.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent gray background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* Ensure the modal is above other content */
}

.popup {
    position: relative;
    background-color: #0B3D59;
    /* background: linear-gradient(to right, #1B75BB, #27A9E1, #49C0B5); */
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    width: 80%;
    height: 80%;
    display: flex;
    flex-direction: column;

    @media (min-width: 768px) {
        flex-direction: row;
    }
}

.closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #ccc;
    border: none;
    color: black;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    cursor: pointer;

    @media (max-width: 768px) {
        font-size: 15px;
        background-color: transparent;
        color: #ccc;
        top: 0px;
        right: 0px
    }
}

.closeButton:hover {
    background-color: #aaa;
    color: black;
}

.overflow {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    overflow-y: scroll;
    max-height: 90%;
}

