.slide {
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    height: 300px;
  }
}

.control-arrow {
  background-color: #1B75BB;
  margin: 0 2px;
  border-radius: 5px;
}