.container {
    max-width: 80rem; /* max-w-7xl */
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    padding: 3rem 2rem;
}

.containerPosition {
    max-width: 80rem; /* 1280px */
    margin-left: auto;
    margin-right: auto;
}

.title {
    font-size: 2.5rem; /* 36px */
    line-height: 2.75rem; /* 40px */
    font-weight: bold;
    color: #0B3D59;
    text-align: center;
}

.title2 {
    font-size: 2.25rem; /* 36px */
    line-height: 2.5rem; /* 40px */
    font-weight: bold;
    color: #F1F1E6;
    letter-spacing: -0.025em; /**/
    text-align: center;
    padding-bottom: 1rem;
}

.landingBtnsContainer {
    margin-top: 2rem;
    width: 20rem;
    display: flex;
    flex: start;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
    border-radius: 0.375rem;
}

.landingBtns {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    border-radius: 0.375rem;
    border-width: 1px;
    border-color: transparent;
    background-color: #0B3D59;
    padding: 0.75rem 2rem;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 600;
    color: white;
    cursor: pointer;
}

.cardContainer {
    /* width: 100px; */
  box-shadow: 0 6px 6px 2px gray;
  text-align: center;
  flex: 3 3 0;
  border-radius: 10px;
  margin: 0 1rem;
  padding: 1rem;
  background-color: white;
}

.cardContainer h3 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 1rem;
    color: #0B3D59
}

.container p {
    font-size: 18px;
    color: #3A4856;
    font-weight: 700;
}

@media (max-width: 1024px) {
    .container {
        padding-left: 1.5rem; /* 24px */
        padding-right: 1.5rem; /* 24px */
    }
}

/* md */
@media (min-width: 768px) {
    .landingBtns {
        padding: 1rem 2.5rem;
        font-size: 1.125rem; /* 18px */
        line-height: 1.75rem; /* 28px */
    }
}

/* sm */
@media (min-width: 640px) and (max-width: 1024px) {
    .landingBtnsContainer {
        justify-content: center;
    }
}

/* xs */
@media (max-width: 640px) {
    .container {
        padding-left: 1rem; /* 16px */
        padding-right: 1rem; /* 16px */
    }

    .title {
        font-size: 1.5rem; /* 24px */
        line-height: 2rem; /* 32px */
    }

    .title2 {
        font-size: 1.5rem; /* 24px */
        line-height: 2rem; /* 32px */
    }

    .landingBtnsContainer {
        margin-top: 1.25rem;
        width: 16.5rem;
        display: block;
    }
}

.bgGradient {
    background: linear-gradient(to right, #1B75BB, #27A9E1, #49C0B5)
}

.hover-bgGradient:hover {
    background: linear-gradient(to right, #1B75BB, #27A9E1, #49C0B5)
}