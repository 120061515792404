.info {
    display: flex;
    align-items: center;
    font-size: 1.25rem; /* 20px */
    line-height: 1.75rem; /* 28px */
    margin-top: 1rem;
    color: white;
    justify-content: center;
}


@media (max-width: 768px) {
    .info {
        font-size: 1.125rem; /* 18px */
    }
}